import React from 'react'
import {FaTelegram, FaFacebook, FaYoutube} from 'react-icons/fa'

import './footer.css'


const Footer = ({color1, color2}) => {
  return (
    <div className='no-wrapper'>
      <div className="footer" style={{background: `linear-gradient(180deg, ${color1}, ${color2})`}}>
        <div className="footer-separator"></div>
        <div className="more-footer-info">
          <p>መንግሥተ ሥላሴ © 2015</p>
          <div className="social-media-links">
        <a href="https://youtube.com">
          <FaYoutube size={25} className="yt"/>
        </a>
        <a href='https://youtube.com' className='fb'>
          <FaFacebook size={25}/>
        </a>
        <a href="https://youtube.com" className='telegram'>
          <FaTelegram size={25}/>
        </a>
      </div>
        </div>
      </div>
    </div>
  )
}

export default Footer