import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Main from "./components/Main/Main";
import Blogs from "./pages/Blogs";
import Videos from "./pages/Videos";
import Books from "./pages/Books";
import Audio from "./pages/Audio";
import About from "./pages/About";
import Faq from "./pages/Faq";

import BlogItemList from "./components/Blog/BlogItemList";
import Transit from "./components/Transit/Transit";
import Pagination from "./components/Blog/Pagination";
import Rainbow from "./components/Rainbow";
import VideoBlock from "./components/Video/Videos";
import Footer from "./components/Footer/Footer";
import BlogDetail from "./pages/BlogDetail";

import "./App.css";
import "./allBlogs";
import allBlogs from "./allBlogs";
import allVideos from "./allVideos";
import allFaqs from './allFaqs'

function App() {
  const [blogItem, setBlogItem] = useState(allBlogs);
  const [videoItem] = useState(allVideos)
  const [faq] = useState(allFaqs)
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(2);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = blogItem.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(blogItem.length / recordsPerPage);

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <div className="wrapper">
                  <Main />
                  <Transit
                    color1={"orange"}
                    color2={"orangered"}
                    color3={"transparent"}
                    color4={"transparent"}
                  />
                  <BlogItemList blogItem={currentRecords} />
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                  <Rainbow />
                  <VideoBlock />
                </div>
                <Footer color1={"#5773E6"} color2={"#43BFEB"} />
              </>
            }
          ></Route>
          <Route path="/blogs" element={<Blogs />}></Route>
          <Route path="/blogs/:blogId" element={<BlogDetail blogItem={blogItem}/>}></Route>
          <Route path="/videos" element={<Videos videoItem={videoItem}/>}></Route>
          <Route path="/books" element={<Books />}></Route>
          <Route path="/audio" element={<Audio />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/faq" element={<Faq faq={faq}/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
