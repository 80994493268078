import React from 'react'
import {useState} from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'


const FaqList = ({faqItem}) => {

  const [isActive, setIsActive] = useState(false)

  return (
    <div>
      <div className="accordion">
            <div className="accordion-item">
              <div className="accordion-title" onClick={()=> setIsActive(!isActive)}>
                <p>{faqItem.title}</p>
                {isActive ? <FaAngleUp className='handleIcon' size={"1.2rem"}/> : <FaAngleDown className='handleIcon' size={"1.2rem"}/>}
              </div>
              <hr />
              {isActive && <div className="accordion-content">{`${faqItem.content}`}</div>}
            </div>
          </div>
    </div>
  )
}

export default FaqList