import React from "react";
import Footer from "../components/Footer/Footer";
import Breadcrumbs from "../components/Breadcrumbs";

const BlogDetail = ({ blogItem }) => {
  return (
    <div>
      <div className="wrapper">
        <Breadcrumbs />
        <div className="b-separator"></div>
        <div className="blog-detail-containerbox">
          <div className="more-from-blogs">
            <h3>ተዛማጅ መልዕክቶች</h3>
            <div className="list-of-categories">
              <a href="#" className="category-box">
                <p className="category-name">እትዮጵያዊ ማንነት</p>
              </a>
              <a href="#" className="category-box">
                <p className="category-name">ተዋህዶ</p>
              </a>
              <a href="#" className="category-box">
                <p className="category-name">ሕግናነቢያት</p>
              </a>
              <a href="#" className="category-box">
                <p className="category-name">የፍርድ ምድር እትዮጵያ</p>
              </a>
              <a href="#" className="category-box">
                <p className="category-name">ቅዱስ ኤልያስ</p>
              </a>
            </div>
            <nav className="fix-spacing-for-pagination">
              <ul className="pagination" id="blogsPaginate">
                <li className="page-item">
                  <a href="#" className="page-link">
                    ቀዳሚ
                  </a>
                </li>
                <li className="page-item page-item-number active">
                  <a href="#" className="page-link">
                    1
                  </a>
                </li>
                <li className="page-item page-item-number">
                  <a href="#" className="page-link">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    ቀጣይ
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="blog-more-detail">
            {blogItem.map((blog) => (
              <>
                <p key={blog.id} className="fix-blog-title-on-detail-page">
                  {blog.blogTitle}
                </p>
                <p key={blog.id} className="fix-detail-show">
                  {blog.blogDetail}
                </p>
              </>
            ))}
          </div>
        </div>
      </div>
      <Footer color1={"#5773E6"} color2={"#43BFEB"} />
    </div>
  );
};

export default BlogDetail;
