import React from 'react'
import {Link} from 'react-router-dom'

import Em from '../../images/em.jpg'
// import ln from '../../images/Lion.jpg'

const BlogItem = ({blogItem}) => {

  // console.log(blogItem)

  return (
    <div className='blog-card'>
      <img src={Em} alt="blog" className='blog-image'/>
      <div className="blog-title-and-detail">
        <Link to={`/blogs/${blogItem.id}`} className='actual-blog-title'>{blogItem.blogTitle}</Link>
        <p className='blog-detail'>
          {
          blogItem.blogDetail.length > 300 ? `${blogItem.blogDetail.substring(0, 300)}...` : blogItem.blogDetail
          }
        </p>
        <a target="_blank" href={`/blogs/${blogItem.id}`} className='read-more-option'>ተጨማሪ ያንብቡ</a>
      </div>
    </div>
  )
}

export default BlogItem