import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";

import "./video.css";

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

const Videos = () => {
  return (
    <div className="video-container">
      <div className="sectionTitle">
        <h2>ቪዲዮዎች</h2>
        <div className="underblock"></div>
      </div>
      <Swiper
        modules={[Navigation, Pagination, EffectCoverflow]}
        navigation
        pagination={{ clickable: true }}
        // effect = "coverflow"
        // coverFlowEffect = {{
        //   rotate: 50,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 1,
        //   slideShadows: false
        // }}
        slidesPerView={1}
        spaceBetween={20}
        // centeredSlides
        style={{ height: "315px", width: "95%" }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
      >
        <SwiperSlide>
          <iframe
            className="fix-video-display"
            style={{ borderRadius: "10px" }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/4w_ZVuWDXt0"
            title="YouTube video player 1"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </SwiperSlide>
        <SwiperSlide>
          <iframe
            className="fix-video-display"
            style={{ borderRadius: "10px" }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/4w_ZVuWDXt0"
            title="YouTube video player 2"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </SwiperSlide>
        <SwiperSlide>
          <iframe
            className="fix-video-display"
            style={{ borderRadius: "10px" }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/4w_ZVuWDXt0"
            title="YouTube video player 3"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </SwiperSlide>
      </Swiper>
      <Link to={"/videos"} className="see-more-videos">
        <p>ተጨማሪ ቪዲዮዎችን ይመልከቱ</p>
      </Link>
    </div>
  );
};

export default Videos;
