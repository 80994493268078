import React from "react";
import { Link } from "react-router-dom";

import "./nav.css";

import Burger from "./Burger";

const Nav = () => {
  return (
    <nav className="navbar">
      <Link
        to="/"
        className="white-clr wrapper-gap-left"
        style={{ fontWeight: "bold", fontSize: "1.4rem" }}
      >
        መንግሥተ ሥላሴ
      </Link>
      <Burger />
      {/* <div className="social-media-links">
        <a href="https://youtube.com">
          <FaYoutube size={25}/>
        </a>
        <a href='https://youtube.com'>
          <FaFacebook size={25}/>
        </a>
        <a href="https://youtube.com">
          <FaTelegram size={25}/>
        </a>
      </div> */}
    </nav>
  );
};

export default Nav;
