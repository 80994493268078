import React from "react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import Lion from "../../images/Lion.jpg";
import Tewahedo from "../../images/tewahedo.png";
import "./main.css";

SwiperCore.use([Navigation, Autoplay]);

const Main = () => {
  return (
    <div>
      <div className="support-overlay">
        <Swiper
          modules={[Navigation, Autoplay]}
          style={{ height: "calc(500px)" }}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log("swiper")}
        >
          <SwiperSlide>
            <img
              src={Tewahedo}
              className="slider-img slider-img-fix"
              alt="slide 2"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={Lion}
              className="slider-img slider-img-fix"
              alt="slide 1"
            />
          </SwiperSlide>
        </Swiper>
        {/* <div className="overlay"></div> */}
      </div>
    </div>
  );
};

export default Main;
