import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Footer from "../components/Footer/Footer";

import bookCover from "../images/bookCover.png";

const Books = () => {
  return (
    <div>
      <div className="wrapper">
        <Breadcrumbs />
        <div className="b-separator"></div>
        <div className="book-container">
          <div className="book">
            <img className="book-img" src={bookCover} alt="book" />
            <div className="book-price-and-actions">
              <div className="title-and-price">
                <p className="book-title">የፍርድ ምድር እትዮጵያ</p>
                <p className="book-price">120 ብር</p>
              </div>
              <a href="#" className="checkout-book">
                አንብብ
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer color1={"#5773E6"} color2={"#43BFEB"} />
    </div>
  );
};

export default Books;
