import React from 'react'
import { FaDownload } from 'react-icons/fa'
import Breadcrumbs from '../components/Breadcrumbs'
import Footer from '../components/Footer/Footer'

import Em from '../images/em.jpg'

const Audio = () => {
  return (
    <div>
      <div className="wrapper">
        <Breadcrumbs/>
        <div className="b-separator"></div>
        <div className="audio">
          <div className="single-audio">
            <img src={Em} alt="audio" />
            <div className="audio-options">
              <p className='audio-title'>ሦስተኛው ቤተመቅደስ ታነፀ</p>
              <p className='download-size'>30.6MB</p>
              <a href="#" className="download-btn">
                <FaDownload size={'0.8rem'} color={'white'}/>
                <p className='download'>ዳውሎድ</p>
              </a>
            </div>
          </div>
          <div className="single-audio">
            <img src={Em} alt="audio" />
            <div className="audio-options">
              <p className='audio-title'>ሦስተኛው ቤተመቅደስ ታነፀ</p>
              <p className='download-size'>30.6MB</p>
              <a href="#" className="download-btn">
                <FaDownload size={'0.8rem'} color={'white'}/>
                <p className='download'>ዳውሎድ</p>
              </a>
            </div>
          </div>
          <div className="single-audio">
            <img src={Em} alt="audio" />
            <div className="audio-options">
              <p className='audio-title'>ሦስተኛው ቤተመቅደስ ታነፀ</p>
              <p className='download-size'>30.6MB</p>
              <a href="#" className="download-btn">
                <FaDownload size={'0.8rem'} color={'white'}/>
                <p className='download'>ዳውሎድ</p>
              </a>
            </div>
          </div>
          <div className="single-audio">
            <img src={Em} alt="audio" />
            <div className="audio-options">
              <p className='audio-title'>ሦስተኛው ቤተመቅደስ ታነፀ</p>
              <p className='download-size'>30.6MB</p>
              <a href="#" className="download-btn">
                <FaDownload size={'0.8rem'} color={'white'}/>
                <p className='download'>ዳውሎድ</p>
              </a>
            </div>
          </div>
          <div className="single-audio">
            <img src={Em} alt="audio" />
            <div className="audio-options">
              <p className='audio-title'>ሦስተኛው ቤተመቅደስ ታነፀ</p>
              <p className='download-size'>30.6MB</p>
              <a href="#" className="download-btn">
                <FaDownload size={'0.8rem'} color={'white'}/>
                <p className='download'>ዳውሎድ</p>
              </a>
            </div>
          </div>
          <div className="single-audio">
            <img src={Em} alt="audio" />
            <div className="audio-options">
              <p className='audio-title'>ሦስተኛው ቤተመቅደስ ታነፀ</p>
              <p className='download-size'>30.6MB</p>
              <a href="#" className="download-btn">
                <FaDownload size={'0.8rem'} color={'white'}/>
                <p className='download'>ዳውሎድ</p>
              </a>
            </div>
          </div>
          <div className="single-audio">
            <img src={Em} alt="audio" />
            <div className="audio-options">
              <p className='audio-title'>ሦስተኛው ቤተመቅደስ ታነፀ</p>
              <p className='download-size'>30.6MB</p>
              <a href="#" className="download-btn">
                <FaDownload size={'0.8rem'} color={'white'}/>
                <p className='download'>ዳውሎድ</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer color1={'#5773E6'} color2={'#43BFEB'}/>
    </div>
  )
}

export default Audio