import React from "react";

import Footer from "../components/Footer/Footer";
import Breadcrumbs from "../components/Breadcrumbs";

import "../App.css";

const About = () => {
  return (
    <>
      <div className="wrapper">
        <Breadcrumbs />
        <div className="b-separator"></div>
        <div className="short-description">
          <p>
            መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ
            ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ
            መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ
            አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ
            አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ
            ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ
            መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ
            አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ
            አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ
            ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ
            መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ
            አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ
            አምላክ
            <br />
            መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ
            ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ
            መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ
            አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ
            አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ
            ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ
            መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ
            አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ
            አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ
            ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ
            መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ
            አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ
            አምላክ
          </p>
        </div>
      </div>
      <Footer color1={"#5773E6"} color2={"#43BFEB"} />
    </>
  );
};

export default About;
