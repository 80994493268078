import React from "react";
import Nav from "./Nav/Nav";

const Header = () => {
  return(
    <header>
      <Nav />
    </header>
  )
}

export default Header 