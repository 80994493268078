import React from 'react'

import './transit.css'

const Transit = ({color1, color2}) => {
  return (
    <div>
      <div className="transit" style={{background: `linear-gradient(90deg, ${color1}, ${color2})`}}>
        <p>
          መንግሥተ ሥላሴ ዘደቂቀ ኤልያስ አሐቲ ጉባዔ ሰማያዊት ዘአርያም ቤተ መቅደስ ቅድመ መንበሩ ለእግዚእነ ወማርያም እመ ኩሉፍጥረት ወላዲተ አምላክ  
        </p>
      </div>
    </div>
  )
}

export default Transit