const allBlogs = [
  {
    id: 1,
    blogTitle: "የፍርድ ምድር እትዮጵያ",
    blogDetail:
      "እኛ እትዮጵያውያንና እትዮጵያውያት አምላካችን እግዚአብሔርን በጣም የምንወድ “የጥበብ ሁሉ መጀመሪያ እግዚአብሔርን መፍራት ነው” የሚለውን አምላካዊና የቅዱሳት መጻሕፍት ቃል የሕይወታችን መመሪያ በማድረግ አጠቃላይ ማንነታችንንና ምንነታችንን ሕልውናችንንም በሙሉ በፍጥረተ ዓለማቱ ፈጣሪና አስገኝ፣ መጋቢና ጠባቂ በሆነው ቸር አባት አምላካችን ልዑል እግዚአብሔር ላይ መሥርተን ሕይወት እምነትና ምግባርም አድርገን ያለን የነበርንና ለዘለዓለምም የምንኖር ታላቅና ብርቱ ሕዝቦች እንደመሆናችን መጠን በእኛ ዘንድ የሚሆንና የሚደረግ የሚጻፍና የሚነገር ማንኛውም ነገር ቢሆን የታመነና እውነተኛ፣ የሚደርስና የሚፈጸም እንጂ ተወርቶና ተነግሮ ብቻ የሚቀር አለመሆኑን ማወቅና መገንዘብ፣ መረዳትና ማስተዋል ይገባል።",
  },
  {
    id: 2,
    blogTitle: "ደጉ ንጉሥ ባለ ትንቢቱ “ቴዎድሮስ” ማን ነው?",
    blogDetail:
      "ሀገረ እግዚአብሔር፤ ሀገረ ድንግል ማርያም፤ ቅድስት ምድር እትዮጵያ የሰው ዘር መገኛ የአባታችን አዳም ስመ ንግሥናው አድማኤል ኪሩብ የእናታችን ሔዋን ስመ ንግሥናዋ እምነ እያኤል (ሕያኤል) ርስትና ጉልት ገነት ምድር መሆኗ ዛሬ በዘመናችን ዓለም ሁሉ ታላቁ መጽሐፍ ብሎ ባመነበትና በተቀበለው መጽሐፍ ቅዱስ ላይ ግልጽ ሆኖ በማያሻማና በማያጠራጥር መልኩ እንደሚከተለው ተከትቦ ተቀምጧል፦",
  },
  {
    id: 3,
    blogTitle: "የእትዮጵያውያን ትንቢት ስለ እትዮጵያ ትንሣኤ",
    blogDetail:
      "በየገዳሙ፣ በየአድባራቱ፣ በየዋሻውና በየፍርኩታው ዘግተው በጾም በጸሎትና በሥግደት ተግተው ፈጣሪያቸውን ቸር አባት ጌታ ልዑል እግዚአብሔርን እያመሰገኑ ለእትዮጵያ እና ለእትዮጵያውያን እየጸለዩ ዘመናቸውን በተጋድሎና በብሕትውና ያሣለፉ ታላላቅ እትዮጵያውያን ቅዱሳን አባቶችና ቅዱሳት እናቶች ሳይቀሩ በስስትና በናፍቆት ሆነው አቤት ከዚያን ዘመን የደረሰ ምንኛ የታደለና በእግዚአብሔር የተወደደ ነው በማለት ለትንቢቱ አትኩሮት ሰጥተው ሲናገሩና ሲያስተላልፉ ኖረዋል።",
  },
];

export default allBlogs;
