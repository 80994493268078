import React from 'react'

const Pagination = ({totalPages, currentPage, setCurrentPage}) => {

  const pageNumbers = [...Array(totalPages + 1).keys()].slice(1)

  const nextPage = () => {
    if(currentPage !== totalPages) setCurrentPage(currentPage + 1)
  }
  
  const prevPage = () => {
    if(currentPage !== totalPages) setCurrentPage(currentPage - 1)
    console.log('previous')  
  }

  return (
    <nav>
      <ul className="pagination" id='blogsPaginate'>
        <li className='page-item'>
          <a href="#blogsPaginate" className="page-link" onClick={prevPage}>
          ቀዳሚ
          </a>
        </li>
          {
            pageNumbers.map((pageNumber) => (
              <li key={pageNumber} className={`page-item-number page-item ${currentPage == pageNumber ? 'active': ''}`}>
                <a href="#blogsPaginate" className='page-link' onClick={() => setCurrentPage(pageNumber)}>
                  {pageNumber}
                </a>
              </li>
            ))
          }
          <li className='page-item'>
            <a href="#blogsPaginate" className='page-link' onClick={nextPage}>ቀጣይ</a>
          </li>
      </ul>
    </nav>
  )
}

export default Pagination