import React from 'react'
// import {useState} from 'react'

import BlogItem from './BlogItem'
// import allBlogs from '../../allBlogs'

import './blog.css'

const BlogItemList = ({blogItem}) => {

  // const [blogItem, setBlogItem] = useState(allBlogs)
  
  if(!blogItem || blogItem.length === 0){
    return <p>No Blogs Yet!!</p>
  }

  return (
    <div>
      <div className="blog-container">
        <div className="sectionTitle">
          <h2>መልዕክቶች</h2>
          <div className="underblock"></div>
        </div>
        {
          blogItem.map((blogItem) => (
            <>
              <BlogItem key={blogItem.id} blogItem={blogItem}/>
              <div className="separator"></div>
            </>     
          ))
        }
      </div>
    </div>
  )
}

export default BlogItemList