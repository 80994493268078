const allVideos = [
  {
    "id": 1,
    "url": "https://www.youtube.com/embed/4w_ZVuWDXt0",
  },
  {
    "id": 2,
    "url": "https://www.youtube.com/embed/CfPIFlqyDhM",
  },
  {
    "id": 3,
    "url": "https://www.youtube.com/embed/RSKiryCG3H0",
  },
  {
    "id": 4,
    "url": "https://www.youtube.com/embed/cj8QGjTVfFo",
  },
  {
    "id": 5,
    "url": "https://www.youtube.com/embed/131qYEAh4ko",
  },
];

export default allVideos;
