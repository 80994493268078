import React from 'react'
import {useState} from 'react'

import Breadcrumbs from '../components/Breadcrumbs'
import BlogItem from '../components/Blog/BlogItem'
import Footer from '../components/Footer/Footer'

import '../allBlogs'
import allBlogs from '../allBlogs'
import { FaScroll } from 'react-icons/fa'

const Blogs = () => {

  const [blog] = useState(allBlogs)

  if(!blog || blog.length === ''){
    return <p>No Blogs Yet!</p>
  }

  return (
    <div>
      <div className="wrapper fix-blog-view">
        <div className="main-blog-list">
          <Breadcrumbs/>
          <div className="b-separator"></div>
            { 
              blog.map((blogItem) => {
                return (
                  <>
                    <BlogItem  key={blogItem.id} blogItem={blogItem}/>
                    <div className="separator"></div>
                  </>
                )
              })  
            }
        </div>
        <div className="blog-categories">
          <h3>ምድቦች</h3>
          <div className="list-of-categories">
            <a href='#' className="category-box">
              <p className='category-name'>እትዮጵያዊ ማንነት</p>
              <FaScroll/>
            </a>
            <a href='#' className="category-box">
              <p className='category-name'>ተዋህዶ</p>
              <FaScroll/>
            </a>
            <a href='#' className="category-box">
              <p className='category-name'>ሕግናነቢያት</p>
              <FaScroll/>
            </a>
            <a href='#' className="category-box">
              <p className='category-name'>የፍርድ ምድር እትዮጵያ</p>
              <FaScroll/>
            </a>
            <a href='#' className="category-box">
              <p className='category-name'>ቅዱስ ኤልያስ</p>
              <FaScroll/>
            </a>
          </div>
        </div>
      </div>
      <Footer color1={'#5773E6'} color2={'#43BFEB'}/>
    </div>
  )
}

export default Blogs