import React from "react";
import { Link, useLocation } from "react-router-dom";

import "../components/Blog/blog.css";

const Breadcrumbs = () => {
  const location = useLocation();

  let currentLink = "";

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, index) => {
      currentLink += `/${crumb}`;

      // turn blogs into the amharic equivalent for displaying it
      switch (crumb) {
        case "blogs":
          crumb = "መልዕክቶች";
          break;
        case "about":
          crumb = "ስለ እኛ";
          break;
        case "videos":
          crumb = "ቪዲዮዎች";
          break;
        case "books":
          crumb = "መጽሐፍት";
          break;
        case "audio":
          crumb = "ኦዲዮ";
          break;
        case "faq":
          crumb = "ተደጋጋሚ ጥያቄዎች";
          break;
        default:
          crumb = "";
      }
      return (
        <div className="crumb" key={index}>
          <Link className="crumb-crumb" to={currentLink}>
            {crumb}
          </Link>
        </div>
      );
    });

  return (
    <div className="breadcrumbs-wrapper">
      <div className="breadcrumbs">
        <h4 className="single-crumb">{crumbs}</h4>
      </div>
    </div>
  );
};

export default Breadcrumbs;
