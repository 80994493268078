import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import Footer from '../components/Footer/Footer'
import FaqList from '../components/FaqList'

const FAQ = ({faq}) => {
  return (
    <div>
      <div className="wrapper">
        <Breadcrumbs/>
        <div className="b-separator"></div>
        <div className="faqs">
          {
            faq.map(faqItem => (
              <FaqList faqItem={faqItem}/>
            ))
          }
        </div>
      </div>
      <Footer color1={'#5773E6'} color2={'#43BFEB'}/>
    </div>
  )
}

export default FAQ