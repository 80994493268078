import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  FaPlayCircle,
  FaBook,
  FaScroll,
  FaHeadphones,
  FaQuestionCircle,
  FaPodcast,
} from "react-icons/fa";
import "./nav.css";

const Ul = styled.ul`
  display: flex;
  gap: 15px;
  list-style: none;
  background-color: #1f79ff;
  padding: 0.8rem 1rem;
  border-radius: 7px;
  margin-right: 7rem;

  li a {
    color: white;
  }

  li a:active {
    background-color: white;
    color: #ff9408;
  }

  li a:hover {
    background: white;
    color: #1f79ff;
    padding: 0.2rem;
    border-radius: 4px;
  }

  @media screen and (max-width: 992px) {
    flex-flow: column nowrap;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    width: 200px;
    height: 100vh;
    z-index: 100;
    margin-right: 0;
    border-radius: 0;
    padding-top: 4.5rem;
    transition: transform 0.3s ease-in-out;

    li a {
      /* transform: ${({ sideBarDisplay }) =>
        sideBarDisplay ? "translateX(0)" : "translateX(100%)"}; */
      /* transform: ${({ open }) =>
        open ? "translateX(0)" : "translateX(100%)"}; */
      visibility: ${({ open }) => (open ? "visible" : "hidden")};
    }

    li:hover a {
      background: white;
      display: block;
      width: 100%;
      padding: 0.2rem;
      border-radius: 4px;
      color: #1f79ff;
    }
  }
`;

const RightNav = ({ open }) => {
  const [sideBarDisplay, setSideBarDisplay] = useState(false);

  // Add handle function when
  // useEffect(() => {
  //   console.log("sidebar");
  // }, []);

  return (
    <Ul open={open} sideBarDisplay={sideBarDisplay}>
      <li>
        <Link
          to={"/blogs"}
          onClick={({ sideBarDisplay }) => setSideBarDisplay(!sideBarDisplay)}
        >
          <FaScroll /> መልዕክቶች
        </Link>
      </li>
      <li>
        <Link to={"/videos"}>
          <FaPlayCircle /> ቪዲዮ
        </Link>
      </li>
      <li>
        <Link to={"/books"}>
          <FaBook /> መጽሐፍት
        </Link>
      </li>
      <li>
        <Link to={"/audio"}>
          <FaHeadphones /> ኦዲዮ
        </Link>
      </li>
      <li>
        <Link to={"/about"}>
          <FaPodcast /> ስለ እኛ
        </Link>
      </li>
      <li>
        <Link to={"/faq"}>
          <FaQuestionCircle /> ተደጋጋሚ ጥያቄዎች
        </Link>
      </li>
    </Ul>
  );
};

export default RightNav;
