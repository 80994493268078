import React from 'react'

import Breadcrumbs from '../components/Breadcrumbs'
import Footer from '../components/Footer/Footer'

const Videos = ({videoItem}) => {
  
  if(!videoItem || videoItem === ""){
    return <p>No Videos Yet!!</p>
  }

  return (
    <>
    <div className='wrapper'>
      <Breadcrumbs/>
      <div className="b-separator"></div>
      <div className="video-list">
        {
          videoItem.map((video) => {
          return(
            <iframe key={video.id} src={`${video.url}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          )
        })
        }
      </div>
    </div>
    <Footer color1={'#5773E6'} color2={'#43BFEB'}/>
    </>
  )
}

export default Videos