import React from 'react'

const Rainbow = () => {
  return (
    <div style={rainbow}></div>
  )
}

const rainbow = {
  background: `linear-gradient(180deg, #5773E6, lightgreen, orange, red)`,
  height: '200px',
  width: '100%',
  marginTop: '2rem'
}

export default Rainbow